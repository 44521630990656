import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAddContactFormMutation } from "../store/rtk/contactUs";
import { useForm } from "react-hook-form";
import { useFetchMenuQuery } from "../store/rtk/menu";
import { useFetchcontactLocationDataQuery } from "../store/rtk/contactLoction";
import Banner from "../Component/Banner";
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";
const Contact = () => {
  const [portfolio, setportfolio] = useState([]);
  const [maiMenu, setMainmenu] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedSubValue, setSelectedSubValue] = useState("");
  const [subMenu, setSubMenu] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [checkCaptcha, setCheckCaptcha] = useState(false);
  const { data: hierarchy } = useFetchMenuQuery();
  const [subPath, setSubPath] = useState("onebkc");

  const recaptcha = useRef();

  const { data: locationData } = useFetchcontactLocationDataQuery({ subPath });
  console.log("data", locationData);
  useEffect(() => {
    menuOrdring(hierarchy);
    getOptions(hierarchy);
  }, [hierarchy]);
  const menuOrdring = (hierarchy) => {
    const filterdData = hierarchy?.find((item) => item.title === "Portfolio");
    let myArray = [];
    filterdData?.children?.forEach((item, index) => {
      myArray.push(item.children);
    });
    setMainmenu(filterdData);
    console.log(maiMenu);
    setportfolio(myArray);
    const flatData = myArray.flat();
    setSubMenu(
      flatData?.filter((item) => {
        return item?.parent?.title === "Mumbai";
      })
    );
    setSubPath("onebkc");
  };

  const getOptions = (hierarchy) => {
    const filterdData = hierarchy?.find((item) => item.title === "Portfolio");
    return (
      filterdData?.children?.map(
        (item, index) => (
          getSubOption(item?.children),
          index <= 4 ? (
            <option key={index} value={item.title}>
              {item.title}
            </option>
          ) : (
            false
          )
        )
      ) || []
    );
    getSubOption(subMenu);
  };

  const getSubOption = () => {
    return (
      subMenu?.map((item, index) => (
        <option key={item?.path} value={item?.path}>
          {item.title}
        </option>
      )) || []
    );
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    const flatData = portfolio.flat();
    const filterteddATA = flatData?.filter((item) => {
      return item?.parent?.title === e.target.value;
    });
    const path = filterteddATA[0]?.path;
    const parts = path.split("/");
    const lastPart = parts.pop();
    setSubPath(lastPart);
    setSubMenu(filterteddATA);
  };

  const handleChangeSubCategri = (e) => {
    setSelectedSubValue(e.target.value);
    const path = e.target.value;
    const parts = path.split("/");
    const lastPart = parts.pop();
    setSubPath(lastPart);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [sendRequest, { isLoading: isSavedData }] = useAddContactFormMutation();

  const onSubmit = async (data) => {
    if (captchaValue) {
      let recivedResponse = await sendRequest({ data });
      if (recivedResponse?.data?.data) {
        reset();
        toast.success("Query send successfully !!!");
      } else if (recivedResponse?.error?.data?.data === null) {
        toast.error(recivedResponse?.error?.data?.error?.message);
      }
    } else {
      setCheckCaptcha(true);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    if (value) {
      setCheckCaptcha(false);
    } else {
      setCheckCaptcha(true);
    }
  };

  const resetData = () => {
    reset();
  };

  return (
    <>
      <Helmet>
        <title>Contact Nucleus Office Parks | Find Your Perfect Office</title>
        <meta name="description" content="Ready to find your ideal office? Reach out to our team for assistance with rentals across India and find the ideal space for your needs." />
      </Helmet>
      <ToastContainer />

      <section className="banner_image_common">
        <Banner urlName={"contact"} />
      </section>

      <section
        className="contact-sec position-relative"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-5  form_wapper_col">
              <div className="contact-form-wrapper">
                <div className="contact-content-box">
                  <h2 className="fs-36 fw-bold contact-title">
                    Registered Office
                  </h2>
                  <address className="address">
                    <ul className="list-unstyled address-list">
                      <li>
                        <svg
                          className="contact-icon location"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 15.6 5.4 37 16.6 63.4c10.9 25.9 26.2 54 43.6 82.1c34.1 55.3 74.4 108.2 99.9 140c25.4-31.8 65.8-84.7 99.9-140c17.3-28.1 32.7-56.3 43.6-82.1C346.6 229 352 207.6 352 192zm32 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z" />
                        </svg>
                        <p className="fs-17 contact-para">
                          One BKC, Wing C, 407, G Block BKC, Bandra East,
                          Mumbai, Maharashtra 400051
                        </p>
                      </li>
                      <li>
                        <svg
                          className="contact-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M64 96c-17.7 0-32 14.3-32 32v39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9V128c0-17.7-14.3-32-32-32H64zM32 207.6V384c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V207.6L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                        </svg>
                        <a
                          className="fs-17 contact-para"
                          href="mailto:info@nucleusofficeparks.com"
                        >
                          info@nucleusofficeparks.com
                        </a>
                      </li>
                      <li>
                        <svg
                          className="contact-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M375.8 275.2c-16.4-7-35.4-2.4-46.7 11.4l-33.2 40.6c-46-26.7-84.4-65.1-111.1-111.1L225.3 183c13.8-11.3 18.5-30.3 11.4-46.7l-48-112C181.2 6.7 162.3-3.1 143.6 .9l-112 24C13.2 28.8 0 45.1 0 64v0C0 300.7 183.5 494.5 416 510.9c4.5 .3 9.1 .6 13.7 .8c0 0 0 0 0 0c0 0 0 0 .1 0c6.1 .2 12.1 .4 18.3 .4l0 0c18.9 0 35.2-13.2 39.1-31.6l24-112c4-18.7-5.8-37.6-23.4-45.1l-112-48zM447.7 480C218.1 479.8 32 293.7 32 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0-3.8 2.6-7 6.3-7.8l112-24c3.7-.8 7.5 1.2 9 4.7l48 112c1.4 3.3 .5 7.1-2.3 9.3l-40.6 33.2c-12.1 9.9-15.3 27.2-7.4 40.8c29.5 50.9 71.9 93.3 122.7 122.7c13.6 7.9 30.9 4.7 40.8-7.4l33.2-40.6c2.3-2.8 6.1-3.7 9.3-2.3l112 48c3.5 1.5 5.5 5.3 4.7 9l-24 112c-.8 3.7-4.1 6.3-7.8 6.3c-.1 0-.2 0-.3 0z" />
                        </svg>
                        <a
                          className="fs-17 contact-para text-black"
                          href="tel:+91 22-68684400"
                        >
                          +91 22-68684400
                        </a>
                      </li>
                    </ul>
                  </address>
                </div>
                <div className="contact-form-wrapper">
                  <h2 className="fs-36 fw-bold contact-title">
                    We'd love to hear from you
                  </h2>

                  {isSavedData && <Loader />}
                  <form
                    action=""
                    className="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="custom-input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        placeholder="Full Name*"
                        name="full_name"
                        {...register("full_name", {
                          required: "Name is required",
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only alphabetic characters are allowed",
                          },
                        })}
                      />
                    </div>
                    {errors.full_name && (
                      <h4 className="text-danger mt-2">
                        {errors.full_name.message}
                      </h4>
                    )}

                    <div className="custom-input-wrapper mt-5">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email ID*"
                        name="email_id"
                        {...register("email_id", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[cC][oO][mM]$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                    </div>
                    {errors.email_id && (
                      <h4 className="text-danger mt-2">
                        {errors.email_id.message}
                      </h4>
                    )}


                    <div className="custom-input-wrapper mt-5">
                      <input
                        type="text"
                        className="form-control"
                        id="cname"
                        placeholder="Company Name*"
                        name="company_name"
                        {...register("company_name", {
                          required: "Company Name is required",
                        })}
                      />
                    </div>

                    {errors.company_name && (
                      <h4 className="text-danger mt-2">
                        {errors.company_name.message}
                      </h4>
                    )}
                    <div className="custom-input-wrapper mt-5">
                      <input
                        type="text" 
                        className="form-control"
                        id="cname"
                        placeholder="Phone No.*"
                        name="phone_number"
                        maxLength={10} 
                        {...register("phone_number", {
                          required: "Phone number is required",
                          pattern: {
                            value: /^[0-9]+$/, 
                            message: "Please enter a numeric number", 
                          },
                          validate: {
                            isExactLength: (value) =>
                              value.length === 10 ||
                              "Phone number must be exactly 10 digits", 
                          },
                        })}
                      />
                    </div>
                    {errors.phone_number && (
                      <h4 className="text-danger mt-2">
                        {errors.phone_number.message}
                      </h4>
                    )}

                    <div className="custom-input-wrapper mt-5">
                      <textarea
                        className="form-control"
                        name="comment"
                        id="comment"
                        rows="5"
                        placeholder="Comment"
                        {...register("comment")}
                      ></textarea>
                    </div>
                    <div className="custom-input-wrapper google-captcha">
                      <div>
                        <ReCAPTCHA
                          checked={checkCaptcha}
                          ref={recaptcha}
                          sitekey="6LcbmhoqAAAAAADXpWum4X6gVaa3z09Z9wbmUEWQ"
                          onChange={handleCaptchaChange}
                        />
                        {checkCaptcha === true ? (
                          <h4 className="text-danger">
                            Please check. " I'm not a robot"
                          </h4>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="custom-input-wrapper">
                      <button className="btn btn-submit" disabled={isSavedData}>
                        
                        {isSavedData ? "Submitting..." : "Submit"}
                      </button>
                      <button className="btn btn-reset" onClick={resetData}>
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-7 sm-map-relative">
              <div className="row pt-md-5 pt-5 pt-lg-0 maps_wrapper_col">
                <div className="col-sm-5">
                  <h2 className="fs-36 fw-bold contact-title">
                    Find Us Near You
                  </h2>
                </div>
                <div className="col-sm-7">
                  <form action="" className="filter-form">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-input-wrapper">
                          <select
                            name="parents"
                            id=""
                            onChange={handleChange}
                            value={selectedValue}
                            className="form-select"
                          >
                            {getOptions(hierarchy)}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custom-input-wrapper">
                          <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={handleChangeSubCategri}
                            value={selectedSubValue}
                          >
                            {getSubOption()}
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 map-col">
                  <div className="map">
                    <div className="ratio ratio-16x9 map-wrapper">
                      <iframe
                        title="tit"
                        src={`https://maps.google.it/maps?q=${locationData?.data?.attributes?.Location_map
                          ?.Tooltip +
                          locationData?.data?.attributes?.Location_map?.Address
                          }&output=embed`}
                        height="100%"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
