import React from "react";

import { useFetchEsgPagesDataQuery } from "../store/rtk/EsgPage";
import Banner from "./Banner";
import { Loader } from "../shared/Loader";

import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { Helmet } from "react-helmet";

const Esg = () => {
  const {isFetching, data } = useFetchEsgPagesDataQuery();

  return (
    <>
      <Helmet>
        <title>ESG Framework for Real Estate- Sustainability and Compliance</title>
        <meta
          name="description"
          content="Explore the ESG framework's impact on real estate. Learn how environmental, social, and governance factors drive sustainable investments and practices."
        />
      </Helmet>
      {/* banner section */}
      {isFetching && <Loader/>}
      <section className="banner_image_common">
        <Banner urlName={"esg"} />
      </section>
      {/* banner section */}

      {/* esg-av-sec */}
      <section
        className="esg-av-sec"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row ">
            <div className="col-sm-10 mx-auto">
              <h2 className="fs-48 fw-bold salient-title  underline-title  position-relative certified-title text-center">
                {data?.data?.attributes?.Title}
              </h2>
              <p
               
                dangerouslySetInnerHTML={{
                  __html: data?.data?.attributes?.Content,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* esg-av-sec */}
            {/* <!-- People, Planet & Processes --> */}
  <section className="esg-people-planet" data-aos="fade-up" data-aos-duration="1500">
    <div className="container">
      <div className="row ">
        <div className="col-sm-12 esg-card-col">

          <h2 className="fs-48 fw-bold salient-title  underline-title  position-relative certified-title text-center">
            ESG</h2>
        </div>
        <div className="pps"
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esc_planet_process?.Content,
          }}
        ></div>
      </div>
      
    </div>
  </section>
  {/* <!-- People, Planet & Processes --> */}
      {/* <!-- esg framework --> */}
      <section
        className="fsg-framework"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esc_framework?.Content,
          }}
        ></div>
      </section>
      {/* <!-- esg framework --> */}

      {/* <!-- gresb section --> */}
      {/* <section className="gresb" data-aos="fade-up" data-aos-duration="1500">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_gresb?.Content,
          }}
        ></div>
      </section> */}
      {/* <!-- gresb section --> */}

      {/* <!-- Considerations section --> */}
      {/* <section
        className="Considerations"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_considerations?.Content,
          }}
        ></div>
      </section> */}
      {/* <!-- Considerations section --> */}

      {/* <!-- ESG Strategy Overview --> */}
      {/* <section
        className="focus-areas"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_focus_areas?.Content,
          }}
        ></div>
      </section> */}
       {/* <section
        className="focus-areas"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_focus_areas?.Content,
          }}
        ></div>

      </section> */}
      {/* <!-- ESG Strategy Overview --> */}

      {/* <!-- Community section --> */}
      <section
        className="community"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Eng_community?.Content,
          }}
        ></div>
      </section>
      {/* <!-- Community section --> */}

      {/* <!-- Nucleus Office Parks’ business aligns with
  the following SDGs: --> */}

      <section className="sdg" data-aos="fade-up" data-aos-duration="1500">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_sdgs?.Content,
          }}
        ></div>
   
      </section>
      {/* <!-- Nucleus Office Parks’ business aligns with
      the following SDGs: --> */}

      {/* <!-- Governance secttion --> */}
      <section
        className="governance mt-5 pt-5"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_governance?.Content,
          }}
        ></div>
      </section>
      {/* <!-- Governance secttion --> */}
      {/* <!-- Achievements section --> */}
      <section
        className="achievements"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="achievements_left">
                <h2 className="achievements_heading fs-48 fw-bold">
                Achievements
                </h2>
                <h1 className="year_outline">FY 2024</h1>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="achievements_right">
                <div className="container">
                  <nav>
                    <div
                      className="nav nav-tabs gallery_tabs achievements_tabs mb-3"
                      id="nav-tab"
                      role="tablist"
                    >
                      {data?.data?.attributes.Esg_achi_tab?.map(
                        (item, index) => {
                         let str = item?.Tab_title;
                          let res = str.split(' ')[0];
                          let tabeTitle = str.replace(/\./g, ""); // Remove all dots
                            return (
                            <React.Fragment key={index}>
                              <button
                                className={`nav-link ms-4 ${
                                  index === 0 ? "active" : ""
                                }`}
                                id="nav-sustainability-tab"
                                data-bs-toggle="tab"
                                data-bs-target={`#nav-${res}`}
                                type="button"
                                role="tab"
                                aria-controls="nav-sustainability"
                                aria-selected="true"
                              >
                                {tabeTitle}
                              </button>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="nuc_galiery achievements_list">
            <div className="tab-content" id="nav-tabContent">
              {data?.data?.attributes?.Esg_achi_tab.map((item, index) => {
                let str = item?.Tab_title;
                let res = str.split(' ')[0];
               return (
                  <div
                    className={`tab-pane fade  ${
                      index === 0 ? "active show" : ""
                    } `}
                    id={`nav-${res}`}
                    role="tabpanel"
                    aria-labelledby="nav-sustainability-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.Tab_content }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Achievements section --> */}

      {/* <!-- Awards And Recognition --> */}
      <section className="awards" data-aos="fade-up" data-aos-duration="1500">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_recognition?.Content,
          }}
        ></div>
       
      </section>
      {/* <!-- Awards And Recognition --> */}

      {/* <!-- Sustainability Targets --> */}
      <section
        className="sustainability"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="achievements_left">
                <h2 className="achievements_heading fs-48 fw-bold">
                  Sustainability Targets
                </h2>
                <h1 className="year_outline">FY 2025</h1>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="achievements_right">
                <div className="container">
                  <nav>
                    <div
                      className="nav nav-tabs gallery_tabs achievements_tabs mb-3"
                      id="nav-tab"
                      role="tablist"
                    >
                        {data?.data?.attributes.Esg_sustain_tab?.map(
                        (item, index) => {
                         let str = item?.Tab_title;
                          let res = str.split(' ')[0];
                            return (
                            <React.Fragment key={index}>
                              <button
                                className={`nav-link ms-4 ${
                                  index === 0 ? "active" : ""
                                }`}
                                id="nav-sustainability-tab"
                                data-bs-toggle="tab"
                                data-bs-target={`#nav-${res}`}
                                type="button"
                                role="tab"
                                aria-controls="nav-sustainability"
                                aria-selected="true"
                              >
                                {item?.Tab_title}
                              </button>
                            </React.Fragment>
                          );
                        }
                      )}
                      
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="nuc_galiery achievements_list">
            <div className="tab-content p-3" id="nav-tabContent">
            {data?.data?.attributes?.Esg_sustain_tab.map((item, index) => {
                let str = item?.Tab_title;
                let res = str.split(' ')[0];
               return (
                  <div
                    className={`tab-pane fade  ${
                      index === 0 ? "active show" : ""
                    } `}
                    id={`nav-${res}`}
                    role="tabpanel"
                    aria-labelledby="nav-sustainability-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.Tab_content }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Sustainability Targets --> */}
      {/* <!-- PPP Report --> */}
      <section
        className="ppp_reports"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
   
        <div className="container">
      <div className="row gx-5 ppp_left_section">
        <div className="col-lg-4 col-md-4">
          <div className="ppp_image">
            <img src={
                    REACT_APP_BASE_BACKEND_URL +
                    data?.data?.attributes?.Esg_sustain_report?.image?.data?.attributes?.url
                  } 
            alt="ppp-reports" className="img-fluid ppp_images"/>
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <h2 className="fs-48 fw-bold underline-title  position-relative ppp_heading">{data?.data?.attributes?.Esg_sustain_report?.Title}</h2>
       <div
          dangerouslySetInnerHTML={{
            __html: data?.data?.attributes?.Esg_sustain_report?.Content,
          }}
        >
          
        </div>
          <div className="aboutbtn">
            <a href="https://nucleusofficeparksesg.com/" target="_blank">
            <button className="btn btn-dark btn-title">Explore More</button></a>
            
          </div>
        </div>
      </div>
    </div>
      </section>
      {/* <!-- PPP Report --> */}
    </>
  );
};

export default Esg;
