import React, { useState } from "react";
import Banner from "./Banner";
import { useFetchNopplshareholdereDataQuery } from "../store/rtk/oicplCommittee";
import { Link } from 'react-router-dom';
import {REACT_APP_BASE_BACKEND_URL} from "../config"
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";

const NopplShareholdersMeetings = () => {
  const {isFetching, data } = useFetchNopplshareholdereDataQuery();
  //const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  // const Month = [
  //   { month: "January" },
  //   { month: "February" },
  //   { month: "March" },
  //   { month: "April" },
  //   { month: "May" },
  //   { month: "June" },
  //   { month: "July" },
  //   { month: "August" },
  //   { month: "September" },
  //   { month: "October" },
  //   { month: "November" },
  //   { month: "December" },
  // ];
  const getCurrentYear = () => new Date().getFullYear();
    const generateYearOptions = () => {
        const startYear = getCurrentYear();
        const endYear = 2020;
        const years = [];
        for (let year = startYear; year >= endYear; year--) {
            years.push(year);
        }
        return years;
    };
    const yearsArray = generateYearOptions();
  const filterFiles = (files) => { 
    return files?.slice().sort((a, b) => b.id - a.id).filter(file => {
        //if (!file.Date) return false;
        const title = file.Title;
        //const monthMatches = !filterMonth || fileDate.toLocaleString('default', { month: 'long' }) === filterMonth;
        const yearMatches = !filterYear || title.includes(filterYear);

        return yearMatches;
    });
};
// Handle month and year changes
//const handleMonthChange = (e) => setFilterMonth(e.target.value);
const handleYearChange = (e) => setFilterYear(e.target.value);
  return (
    <>
      <Helmet>
        <title>Nucleus office park | Investors</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
      {/* banner */}
      {isFetching && <Loader/>}
      <section className="banner_image_common">
                     <Banner urlName={"investor-sub-pages"} />
            </section>
      {/* banner */}

      {/* NopplShareholdersMeetings */}
      <section className="investore">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light nav_section">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  id="nav-one-link"
                  to="/oicpl-shareholders-meetings"
                  role="tab"
                  aria-controls="nav-one"
                  aria-selected="true"
                >
                  One International Center Private Limited
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  id="nav-office-link"
                  to="/investors"
                  role="tab"
                  aria-controls="nav-office"
                  aria-selected="false"
                >
                  Nucleus Office Parks Private Limited
                </Link>
              </li>
            </ul>
          </nav>
          <div className="row investore_heading justify-content-between  ">
            <div className="col-md-3">
              <h1 className="mt-3 mb-lg-0 mb-4">
                <Link to="/investors">
                  <i className="bi bi-arrow-left"></i> Back
                </Link>
              </h1>
            </div>
            <div className="col-md-6 text-center">
              <h1 className="pt-0 ">{data?.data?.attributes?.Title}</h1>
            </div>
            <div className="col-md-3">
            <form action="" className="filter-form">
                                <div className="row justify-content-lg-end  justify-content-center mt-5">
                                    {/* <div className="col-6">
                                        <div className="custom-input-wrapper">
                                            <select className="form-select" value={filterMonth} onChange={handleMonthChange}>
                                                <option value="">Month</option>
                                                {Month.map((item, index) => (
                                                    <option key={index} value={item.month}>{item.month}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-6">
                                        <div className="custom-input-wrapper">
                                            <select className="form-select" value={filterYear} onChange={handleYearChange}>
                                            <option value="">Year</option>
                                                {yearsArray.map((year, index) => (
                                                    <option key={index} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>



                            </form>
            </div>
          </div>
          <div className="nuc_galiery investore_page ">
            <div className="row  gx-5 mb-2">
            {filterFiles(data?.data?.attributes?.Shareholder_file || []).map((item, i) => (
                <div className="col-lg-6 col-md-6 col-12" key={item.id}>
                    <Link to={`${REACT_APP_BASE_BACKEND_URL}${item?.File?.data?.attributes.url}`} target="_blank" className="inv_data">
                        <div className="commitem">
                            <div className="comm-title">
                                <i className="bi bi-file-earmark-text"></i>
                                {item.Title} {(item.Date!=null)? " - "+item.Date :""}
                            </div>
                            <div className="btncol">
                                <i className="bi bi-arrow-right right"></i>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
            </div>
          </div>
        </div>
      </section>
      {/* NopplShareholdersMeetings */}
    </>
  );
};
export default NopplShareholdersMeetings;
