import React from "react";
import { useFetchNopplcontactDataQuery } from "../store/rtk/oicplCommittee";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";

const NopplContactus = () => {
    const {isFetching, data } = useFetchNopplcontactDataQuery();
    
    return (
        <>
          <Helmet>
        <title>Nucleus office park | Investors</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
          {isFetching && <Loader/>}
            {/* banner */}
            <section className="banner_image_common">
                     <Banner urlName={"investor-sub-pages"} />
            </section>
            {/* banner */}

            {/* NopplContactus */}
            <section className="investore">
                <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light nav_section">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link " id="nav-one-link" to="/oicpl-contactus" role="tab" aria-controls="nav-one" aria-selected="true">
                                    One International Center Private Limited
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" id="nav-office-link" to="/investors" role="tab" aria-controls="nav-office" aria-selected="false">
                                    Nucleus Office Parks Private Limited
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="row investore_heading justify-content-between  "><div className="col-md-2"><h1 className="mt-3 mb-lg-0 mb-4">
                        <Link to="/investors"><i className="bi bi-arrow-left"></i> Back</Link></h1></div><div className="col-md-8 text-center"><h1 className="pt-0 ">{data?.data?.attributes?.Title}</h1></div><div className="col-md-2"></div></div>
                    <div className="nuc_galiery investore_page ">
                        <h2 dangerouslySetInnerHTML={{__html:data?.data?.attributes.Content}}></h2>
                        {/* <div className="row  gx-5 mb-2">
                         
                            <div className="col-md-12">
                                <table className="table investors_tables table-striped table-bordered">
                                    <thead>
                                        <tr className="table_head">
                                            <th>Field</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Company Name</td>
                                            <td>Nucleus Office Parks Private Limited</td>
                                        </tr>
                                        <tr>
                                            <td>CIN</td>
                                            <td>U45309MH2020FTC338743</td>
                                        </tr>
                                        <tr>
                                            <td>Registered Office Address</td>
                                            <td>One BKC, C Wing 407, Plot No. C-66, G-Block, Bandra Kurla Complex, Bandra(E) Mumbai – 400051, Maharashtra, India</td>
                                        </tr>
                                        <tr>
                                            <td>Contact No.</td>
                                            <td>+91 22 68684400</td>
                                        </tr>
                                        <tr>
                                            <td>Fax No., if any</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>Email and the name of the person who may be contacted in case of any queries or grievances including name and details of the company secretary and compliance officer.</td>
                                            <td>
                                                <strong>Name:</strong> Sunil Kumar Tibra<br />
                                                <strong>Designation:</strong> Assistant Vice President – Finance & Compliance<br />
                                                <strong>Email:</strong> <a href="mailto:sunil.tibra@nucleusofficeparks.com">sunil.tibra@nucleusofficeparks.com</a><br />
                                                <strong>Contact No.:</strong> + 91 22 68684434
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* NopplContactus */}
        </>
    );
};
export default NopplContactus;
