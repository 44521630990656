import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const NotFound = () => {
    
  return (
    <>
      <Helmet>
        <title>Page Not Found </title>
        
      </Helmet>
    <section className='not_found'>
      <div class="container">
    <div class="row">
      <div class="col-md-12 error-container">
        <h1>404</h1>
        <p class="lead">Oops! Page Not Found</p>
        <p className='page_error'>It looks like the page you’re looking for has gone missing. Don’t worry, it happens to the best of us! Please head back to our homepage by clicking the button below. We’re here to help you find what you need!</p>
        <Link to="/" class="btn btn-home go_back">Go Back Home</Link>
      </div>
    </div>
  </div>
    </section>
    
    </>
  )
}

export default NotFound