import React from "react";
import fb from "../assets/images/facebook-f.svg";
import link from "../assets/images/linkedin-in.svg";
import insta from "../assets/images/instagram.svg";
import youTube from "../assets/images/youtube-brands-solid.svg";
import user from "../assets/images/user-light.svg";
import calander from "../assets/images/calendar-days-light.svg";
import { useParams } from "react-router-dom";
import {  useFetchBlogDataDetailQuery } from "../store/rtk/blogs";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  const params = useParams();
  const {isFetching,data}=useFetchBlogDataDetailQuery({params}) 
  const getDay = (dateString) => {
    const date = new Date(dateString);
    return date.getDate(); 
  };
const getYear=(dateString)=>{
  const date = new Date(dateString);
  return date.getFullYear();
}
  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthIndex];
  };
  return (
    <>
<Helmet>
        <title>Nucleus office park | Blog Details</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
    <section className="blog-details_section">
        {isFetching && <Loader/>}
      <div className="container">
        <div className="blogdetails">
          <div className="row">
          
            <div className="col-lg-11 col-md-10 col-9">
              <h1>
              {data?.data?.attributes?.Title}
              </h1>
              <div className="author_info_wrap">
              <div className="admin_icon">
                <img src={user} alt-=""/>
                 <p className="author">{data?.data?.attributes?.Posted_by}</p>
              
              </div>
              <div className="admin_icon">
              <img src={calander}/>
              <p className="author"> {getMonthName(data?.data?.attributes?.Posted_date)}<span> </span> {getDay(data?.data?.attributes?.Posted_date)}, {getYear(data?.data?.attributes?.Posted_date)}</p>
              </div>
              </div>
             
            </div>
          </div>
          <div className="blogbanner">
          <img src={REACT_APP_BASE_BACKEND_URL + data?.data?.attributes?.Image?.data?.[0]?.attributes?.url} alt="" className="img-fluid" />
          </div>
          <div className="blogdescription">
          <p dangerouslySetInnerHTML={{ __html:data?.data?.attributes?.Content}}></p>
            <div className="socialshare">
              <p className="mb-0">Share this post</p>
               <div className="social_blog">
               <a href="https://m.facebook.com/nucleusofficeparks/" className="social-icon" target="_blank">
                  <img src={fb} alt="facebook" />
                </a>
                <a href="https://www.youtube.com/@nucleusofficeparks4104" className="social-icon" target="_blank">
                  <img src={youTube} alt="youTube" />
                </a>
                <a href="https://www.linkedin.com/company/nucleus-office-parks/" className="social-icon" target="_blank">
                  <img src={link} alt="linkedin" />
                </a>
                <a href="https://www.instagram.com/nucleusofficeparks/" className="social-icon" target="_blank">
                  <img src={insta} alt="instagram" />
                </a>
                
               </div>
              </div>
          </div>
          
          {/* <div className="blogbtn">
							<div>
								
								<a href="blog-details" className="btnblack">Prev</a>
							</div>
							<div>
								
								<a href="blog-details" className="btnblack">Next</a>
							</div>
					
						</div> */}
        </div>
      </div>
    </section>
    </>
  );
};

export default BlogDetails;
