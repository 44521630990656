import React, { useEffect, useState } from 'react';
import Logo from "../Component/Logo"
import { List } from "../Component/Common/List"
import { Accordions } from "../Component/Common/Accordions"
import fb from "../assets/images/facebook-f.svg";
import link from "../assets/images/linkedin-in.svg";
import insta from "../assets/images/instagram.svg";
import youTube from "../assets/images/youtube-brands-solid.svg";
import { REACT_APP_BASE_BACKEND_URL } from '../config';
import { useFetchMenuQuery } from '../store/rtk/menu';
import { useForm } from 'react-hook-form';
import { useCreateSubscribesMutation } from '../store/rtk/Subscribes';

const Footer = () => {
  return (
    <>
      <section className="main_top footer_hide_inmobile">
        <FooterContent isMobile={false} />
      </section>
      <section className="main_top show_inmobile">
        <FooterContent isMobile={true} />
      </section>
    </>
  );
};

const FooterContent = ({ isMobile }) => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [quicklinkData, setQuicklinkData] = useState([]);
   
  const [siteDetailData, setSiteDetailData] = useState([]);
  const { data: hierarchy } = useFetchMenuQuery();
  const [error, setError] = useState(null);
  const [portfolio, setportfolio] = useState([]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_BASE_BACKEND_URL}/api/portfolios?populate=*`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch portfolio data");
        }
        const data = await response.json();
        setPortfolioData(data.data);
       
        
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();

    //-------------
    const fetchQuicklinkData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_BASE_BACKEND_URL}/api/quicklinks?populate=*`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch portfolio data");
        }
        const data = await response.json();
        setQuicklinkData(data.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchQuicklinkData();

    //--------------------------------------
    const fetchSiteDetail = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_BASE_BACKEND_URL}/api/site-addresses?populate=*`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch portfolio data");
        }
        const data = await response.json();
        setSiteDetailData(data.data);
        
        
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSiteDetail();
  }, []);
  useEffect(() => {
    menuOrdring(hierarchy);
  }, [hierarchy]);
  const menuOrdring = (hierarchy) => {   
    const filterdData = hierarchy?.find((item) => item.title === "Portfolio");
    let myArray = [];
    filterdData?.children?.forEach((item, index) => {
      myArray.push(item.children);
    });
    setportfolio(myArray);
  };
  if (error) {
    return <div>Error: {error}</div>;
  }
  // Filter for "About Us" to "Latest News"
  const aboutUsToLatestNews = quicklinkData.filter((item) => {
    const name = item.attributes.QuicklinkName.toLowerCase();
 
    return (
      name === "about us" ||
      name === "esg" ||
      name === "media" ||
      name === "events" ||
      name === "latest news" ||
      name === "careers"
    );
  });

  // Filter for "Careers" to "Privacy Policy"
  const careersToPrivacyPolicy = quicklinkData.filter((item) => {
    const name = item.attributes.QuicklinkName.toLowerCase();
    return (
      name === "regulatory filing" ||
      name === "blogs" ||
      name === "contact us" ||
      name === "privacy policy"
    );
  });

  const combinedLinks = [...aboutUsToLatestNews, ...careersToPrivacyPolicy];
  return (
    <footer className="footer_section">
      <div className="container">
        <div className="row footer_menu_section">
          <Logo />
          <div className='col-lg-2 col-md-3'>
            <div className='footer_menu'>
              <FooterSection title="Portfolio" items={portfolio} isMobile={isMobile} />
            </div>
          </div>
          <div className="col-lg-2 col-md-3">
            <div className='footer_menu'>
              <FooterSection title="Quick Links" items={isMobile ? combinedLinks : aboutUsToLatestNews} isMobile={isMobile} />

            </div>
          </div>
          <div className={`${!isMobile ? "col-lg-2 col-md-2" : "d-none"}`}>
            <div className='footer_menu'>
              {!isMobile && <FooterSection items={careersToPrivacyPolicy} />}

            </div>
          </div>
          <FooterContact isMobile={isMobile} />
          <div className='social_mobile'>
            {isMobile && <SocialIcons />}
          </div>
        </div>
      </div>
      <FooterBottom isMobile={isMobile} />

    </footer>
  );
};


const FooterSection = ({ title, items, isMobile }) =>{
  return(  <div className={` ${title ? '' : 'mt-5'}`}>
    {!isMobile && <p>{title}</p>}
    {isMobile ? (
      <Accordions title={title} items={items} />
    ) : (
      <List title={title} items={items} />
    )}
  </div>);
} 
const FooterContact = ({ isMobile }) => (
  <div className={`col-lg-4 col-md-4 ${isMobile ? '' : 'col-md-4'}`}>
    <div className="menu_item contact_section">
      {isMobile ? (
        <Accordions
          title="Contact"
          items={[
            { id: 1, content: <ContactContent /> }
          ]}
        />
      ) : (
        <>
          <p className="conatct_para">Contact</p>
          <ContactContent />
        </>
      )}
    </div>
  </div>
);

const ContactContent = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [message,setMessage]=useState("")
  const [createSubscribes, { isLoading: createSubscribesLoading }] =
  useCreateSubscribesMutation();
  const onSubmit =async (data) => {
     try {
     const res=  await createSubscribes(data);
     if(res){
      setMessage("Success!")
      reset()
      setTimeout(()=>{
        setMessage("")
      },3000)
     }else{
      setMessage("fail!")
     }
         
    } catch (error) {
      
    }
  };
  return(
  <>
    <div className="contact_address">
      <p>One BKC, Wing C, 407, G Block BKC, Bandra East, Mumbai, Maharashtra 400051</p>
      <p>For latest updates, <strong>Subscribe here</strong></p>
    </div>
    <form onSubmit={handleSubmit(onSubmit)}>
            <div className="email_section">
                <input
            
                  {...register("Email_id", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[cC][oO][mM]$/,
                      message: "Invalid email address",
                    },
                  })}
                  type="text"
                  placeholder="Enter your email address"
                  className="input_box"
                />
             

                <div className="submit_btn">
                  <button type="submit" className="btn btn-info" disabled={createSubscribesLoading}>
                    Subscribe
                  </button>
                </div>
                
                </div>
   
             {errors.Email_id && (
                  <span className="error">{errors.Email_id.message}</span>
                )}
             {message&&<span className="success">{message}</span>}
              </form>
  </>
);
}

const FooterBottom = ({ isMobile }) => (
  <div className="bottom_footer">
    <div className="container">
      <div className="footer_text">

        <div className="copy">
          <p>Copyright © 2024 Nucleus Office Parks - All rights reserved.</p>
        </div>

        {!isMobile && <SocialIcons />}
      </div>
    </div>
  </div>
);

const SocialIcons = () => (
  <div className="social-icon">
    <a href="https://m.facebook.com/nucleusofficeparks/" target="_blank" rel="noopener noreferrer">
      <img src={fb} alt="facebook" className="img-fluid" />
    </a>
    <a href="https://www.youtube.com/@nucleusofficeparks4104" target="_blank" rel="noopener noreferrer">
      <img src={youTube} alt="youTube" className="img-fluid" />
    </a>
    <a href="https://www.linkedin.com/company/nucleus-office-parks/" target="_blank" rel="noopener noreferrer">
      <img src={link} alt="linkedin" className="img-fluid" />
    </a>
    <a href="https://www.instagram.com/nucleusofficeparks/" target="_blank" rel="noopener noreferrer">
      <img src={insta} alt="instagram" className="img-fluid" />
    </a>
  </div>
);

export default Footer;
