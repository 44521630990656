import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { useFetchNewsDataQuery } from '../store/rtk/news';
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";

function News() {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const { isFetching, isLoading, data } = useFetchNewsDataQuery({ currentPage, pageSize });

  const [allNews, setAllNews] = useState([]);
  
  useEffect(() => {
    if (data) {
      setAllNews(prevNews => [...prevNews, ...data.data]);
    }
  }, [data]);

  const handleShowMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const hasMore = currentPage < Math.ceil(data?.meta?.pagination?.total / pageSize);

  return (
    <>
    <Helmet>
        <title>Nucleus Office Parks- Top News & Updates You Need to Know </title>
        <meta
          name="description"
          content="Get the most recent updates and news on Nucleus office parks. Stay informed about significant changes and trends in the office park sector. "
        />
      </Helmet>
     {isFetching && <Loader/>}
      <section className="banner_image_common">
        <Banner urlName={"news"} />
      </section>
    
      <section className="news">
        <div className="container">
          <div className="row gx-md-5">
            {allNews.map((newsitem) => {
              const newsDate = new Date(newsitem?.attributes?.news_date);
              // Format the date
              const options = { year: 'numeric', month: 'short', day: 'numeric' };
              const formattedDate = newsDate.toLocaleDateString('en-US', options);
              return (<div key={newsitem.id} className="col-lg-4 col-md-6 card-group nuc_galiery ">                
                <div className="card news-card">
                  {newsitem?.attributes?.gallery?.data?.length > 0 && (
                    <img
                      src={`${REACT_APP_BASE_BACKEND_URL + newsitem?.attributes?.gallery?.data[0].attributes?.url}`}
                      alt={newsitem?.attributes?.gallery?.data[0].attributes.name}
                      className="card-img-top img-fluid"
                    />
                  )}
                  <div className="card-body news-card-body">
                    <h3 className="fs-18 fw-bold title mb-0">
                      {formattedDate}
                    </h3>
                    <p
                      className="card-text fs-17 mb-0 text-black"
                      dangerouslySetInnerHTML={{
                        __html: newsitem?.attributes?.news_content,
                      }}
                    ></p>
                    <p className="fs-18 fw-600 mb-0 card-footer-text">
                      News In:{" "}
                      {(newsitem?.attributes?.news_tab==null || newsitem?.attributes?.news_tab=="")? <a href={newsitem?.attributes?.news_url} target="_blank"><span className="fs-18 fw-600 text-blue">
                      {newsitem?.attributes?.news_media_type}
                      </span></a> : <div dangerouslySetInnerHTML={{ __html: newsitem?.attributes?.news_tab, }} ></div>}                         
                    </p>
                  </div>
                </div>
              </div>)
})}
          </div>
          {!isLoading && !isFetching && hasMore && (
            <div className="show_more">
              <button className="btn btn-dark btn-title" onClick={handleShowMore}>SHOW MORE</button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default News;
