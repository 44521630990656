import React from "react";
import { useFetchCommunicationsDataQuery } from '../store/rtk/Communications';
import Banner from "./Banner";
import { Link } from "react-router-dom";
import {  REACT_APP_BASE_BACKEND_URL } from '../config';
import { Loader } from "../shared/Loader";
import aug from "../assets/images/connections-aug2024.png"
import april from "../assets/images/connections-april2024.png"
import june from "../assets/images/connections-june2024.png"
import july from "../assets/images/connections-july2024.png"
import may from "../assets/images/connections-may2024.png"
import march from "../assets/images/connections-march2024.png"
import September from "../assets/images/connections-september2024.PNG"
import { Helmet } from "react-helmet";

const Poster=[
  {
    title:"Connections - September 2024",
    image:September
  },
  {
    title:"Connections - August 2024",
    image:aug
  },
  {
    title:"Connections - April 2024",
    image:april
  },
  {
    title:"Connections - June 2024",
    image:june
  },
  {
    title:"Connections - July 2024",
    image:july
  },
  {
    title:"Connections - May 2024",
    image:may
  },
  {
    title:"Connections - March 2024",
    image:march
  },
]


export const Communications = () => {
  const { isFetching, data } = useFetchCommunicationsDataQuery()
  

  return (
    <>
    <Helmet>
        <title>Nucleus office park | Communications</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
      {/* banner */}
      {isFetching && <Loader />}
      <section className="">
        <Banner urlName={"communications"} hideUnderline={true} />
      </section>
      {/* banner */}

      {/* Events */}
      <section className="news" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row gx-md-5">
            {data?.data.map((item, index) => {
              const url=item?.attributes?.zip_file?.data?.attributes?.name;
                const fileName = url;
              const newFileName = fileName.replace(/\.zip$/i,"");
              const poster = Poster.find(p => p.title === item?.attributes?.Title);
              return(
                <div className="col-lg-6 col-md-6 col-12 " key={index}>
                <Link to={item?.attributes.Document_type === "PDF" ? `${REACT_APP_BASE_BACKEND_URL}${item?.attributes?.PDF_file?.data?.attributes?.url}` : `${REACT_APP_BASE_BACKEND_URL}/${`uploads/${newFileName}/index.html`}`}
                  target="_blank"
                  className="inv_data"
                >
                  <div className="commitem">
                  {poster && (
                    <div className="poster_img">
                      <img src={poster.image} alt={poster.title} className="img-fluid" />
                    </div>
                  )}
                    <div className="comm-title">
                      {/* <i className="bi bi-file-earmark-text"></i> */}
                    {item?.attributes?.Title}
                    </div>
                    <div className="btncol">

                      <i className="bi bi-arrow-right right"></i>


                    </div>
                  </div>
                </Link>
              </div>
              );
            })}


          </div>
        </div>
      </section>
      {/* Events */}
    </>
  );
};
