import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useFetchPortfolioFintechOneDataQuery } from '../store/rtk/PortfolioFintechOne';

import right from '../assets/images/circle-chevron-right-light.svg';
import left from '../assets/images/circle-chevron-left-light.svg';
import { REACT_APP_BASE_BACKEND_URL } from '../config';
import { Modals } from './Common/Modals';
import { SubBannerDetails } from './SubBannerDetails';
import { Loader } from '../shared/Loader';
import Center from './Common/Center';
import ScrollSpy from 'react-ui-scrollspy';
import NavigationItem from './Common/NavigationItem';
import { Helmet } from 'react-helmet';
export const FintechOne = () => {

  const swiper = useSwiper();
  const { isFetching, data } = useFetchPortfolioFintechOneDataQuery();

  let imageData = data?.data?.attributes?.Salient_feature?.images?.data
  const [show, setShow] = useState(false);
console.log("location",data?.data?.attributes?.Location_map?.Address);

  const [loading, setLoading] = useState(false);
  const handleShow = () => {
    setShow(true)
    setLoading(true)
    let loader = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => {
      clearImmediate(loader)
    }
  };

  return (
    <>
    <Helmet>
        <title>Office Space in Fintech One, Ahmedabad | Available Now</title>
        <meta
          name="description"
          content="Searching for the perfect office space in Fintech One, Ahmedabad? Find modern, ready-to-use spaces for your business. Get your ideal workspace now!"
        />
      </Helmet>
      {isFetching && <Loader />}
      {/* bkc section */}
      <SubBannerDetails urlName={"fintech-one"} />
      {/* bkc section */}
      {/* <!-- scrollspy section start --> */}
      <section className="single-page custom-scroll-snap" data-aos="fade-up" data-aos-duration="1500">
        <NavigationItem />
        <div className="container-fluid scrollspay-container">
          <div className=" custom-scrollsy-spacing">

            <ScrollSpy>
              {/* <!-- overview content section start --> */}
              <Center id="overview">
                <section className="quintessential-firm custom-scroll-margin">
                  <div className="container">
                    <div className="row quantential-first-row align-items-center">
                      <div className="col-lg-7 col-md-5">
                        <img src={REACT_APP_BASE_BACKEND_URL + data?.data?.attributes?.Overview_content.image?.data?.attributes?.url} alt="quintessential.jpg" className="img-fluid" />
                      </div>
                      <div className="col-lg-5 col-md-7">
                        <div className="quintessential-content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data?.data?.attributes?.Overview_content.Section1,
                            }}
                          ></div>

                          <a className="cta-map-location" onClick={handleShow}>See location map </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row bg-elevate">
                    <div className="col-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.data?.attributes?.Overview_content.section2,
                        }}
                      ></div>

                    </div>
                  </div>

                </section>
              </Center>
              {/* <!-- overview content section ends --> */}
              <Center id="salient-feature">
                <section className="salient-feature custom-scroll-margin">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="fs-48 fw-bold salient-title underline-title position-relative">
                          {data?.data?.attributes?.Salient_feature?.Title}
                        </h2>
                      </div>
                    </div>
                    <div className="row align-items-center salient-card-row gy-4">
                      <div className="col-lg-7 order-xl-1 order-2">
                        <div dangerouslySetInnerHTML={{
                          __html:
                            data?.data?.attributes?.Salient_feature?.Content

                        }}>
                        </div>
                      </div>
                      <div className="col-lg-4 position-relative order-lg-2 order-1 ms-auto">
                        <div
                          className="container swiper_slider"
                          style={{ position: "relative" }}
                        >
                          <Swiper
                            modules={[
                              Navigation,
                              Pagination,
                              Scrollbar,
                              A11y,
                              Autoplay,
                            ]}
                            navigation={{
                              nextEl: ".swiper-button-next",
                              prevEl: ".swiper-button-prev",
                            }}
                            pagination={false}
                            loop={false}

                            slidesPerView={1}
                            autoplay={{
                              delay: 1500,
                              disableOnInteraction: false,
                            }}
                            initialSlide={1}
                            className="salient-slides"
                            breakpoints={{
                              640: {
                                slidesPerView: 1,
                                spaceBetween: 5,
                              },

                              768: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                              },

                              1024: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                              },
                            }}
                          >
                            {imageData?.map((photo, slideIndex) => (
                              <SwiperSlide
                                key={slideIndex}
                                className="salient-feature-img"
                              >
                                <img className="img-fluid" src={REACT_APP_BASE_BACKEND_URL + photo?.attributes?.url} alt="Card imag cap" />

                              </SwiperSlide>
                            ))}
                          </Swiper>

                          {/* Custom Navigation Buttons */}

                          <button
                            className="swiper-button-next salient-controler"
                            onClick={() => swiper?.slideNext()}
                          >
                            <img src={right} alt="" className="img-fluid" />
                          </button>
                          <button
                            className="swiper-button-prev salient-controler"
                            onClick={() => swiper?.slidePrev()}
                          >
                            <img src={left} alt="" className="img-fluid" />
                          </button>
                          {/* Custom Navigation Buttons */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Center>
              <Center id="sustainability">
                <section className="sustainability custom-scroll-margin">
                  <div className="container">
                   
                    <div dangerouslySetInnerHTML={{
                      __html:
                        data?.data?.attributes?.Sustainability?.Content

                    }}>
                    </div>

                  </div>
                </section>
              </Center>
              <Center id="certification">
                <section className="certification custom-scroll-margin">
                  <div className="container">
                    <h2 className="fs-48 fw-bold salient-title  underline-title  position-relative certified-title text-center">
                      {data?.data?.attributes?.Excellence?.Title}
                    </h2>
                    <div className="row excelence-para-row">
                      <div className="col-sm-6 mx-auto">

                        <div dangerouslySetInnerHTML={{
                          __html:
                            data?.data?.attributes?.Excellence?.Content

                        }}>
                        </div>
                      </div>
                    </div>
                    <div className="row certified-slide-row">
                      <div className="col-sm-12">
                        {/* <!-- Slider main container --> */}
                        <div
                          className="container swiper_slider"
                          style={{ position: "relative" }}
                        >
                          {(data?.data?.attributes?.Excellence?.images?.data?.length > 0) ? <Swiper

                            modules={[
                              Navigation,
                              Pagination,
                              Scrollbar,
                              A11y,
                              Autoplay
                            ]}
                            slidesPerView={4}
                            spaceBetween={0}
                            speed={1500}
                            loop={true}
                            autoplay={{
                              delay: 0,
                              disableOnInteraction: false,
                              pauseOnMouseEnter: true,
                            }}

                            allowTouchMove={false}
                            disableOnInteraction={true}
                            className="certified-slides"
                            breakpoints={{
                              320: { slidesPerView: 2 },
                              768: { slidesPerView: 3 },
                              1024: { slidesPerView: 4 },
                            }
                            }


                          >
                            {data?.data?.attributes?.Excellence?.images?.data?.map((photo, index) => (
                              <SwiperSlide key={index}>
                                <div className="swiper-slide">
                                  <img src={REACT_APP_BASE_BACKEND_URL + photo?.attributes?.url} alt={`Slide ${index}`} className="img-fluid certified-logo" />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper> : ""}

                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Center>
              <Center id="gallery">
                <section className="gallery custom-scroll-margin">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 position-relative">
                        <h2 className="fs-48 fw-bold salient-title  underline-title  position-relative underline-center text-center">
                          Gallery
                        </h2>
                      
                        <div className="gallery-tabs">
                          <ul className="nav nav-pills">
                            {data?.data?.attributes?.Gallery?.map((gallery_image, index) => (
                              <li className="nav-item">
                                <a
                                  className={index === 0 ? "nav-link active" : "nav-link"}
                                  aria-current="page"
                                  data-bs-toggle="pill"
                                  href={"#" + gallery_image?.Gallery_type?.replace(/\s+/g, "-").toLowerCase()}
                                >
                                  {gallery_image?.Gallery_type
                                  }
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-content gallery-tab-content"
                      id="myTabContent"
                    >
                      {data?.data?.attributes?.Gallery?.map((gallery_image, index1) => (
                        <div
                          className={index1 === 0 ? "tab-pane fade show  active" : "tab-pane fade"}
                          id={gallery_image?.Gallery_type?.replace(/\s+/g, "-").toLowerCase()}
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="row gallery-card-row gx-5 gy-4">
                            {gallery_image?.images?.data?.map((gallery_image1, index) => (
                              <div className="col-sm-4">
                                <div className="card border-0 gallery-card">
                                  <img src={REACT_APP_BASE_BACKEND_URL + gallery_image1?.attributes?.url} alt={`Slide ${index}`} className="img-fluid" />
                                </div>
                              </div>
                            ))}

                          </div>
                        </div>
                      ))}


                    </div>
                  </div>
                </section>
              </Center>

            </ScrollSpy>

          </div>
        </div>
      </section>
      <Modals
        show={show}
        loading={loading}
        setShow={setShow}
        data={data?.data?.attributes?.Location_map}
        location={data?.data?.attributes?.Location_map}
      />
    </>
  )
}
