import React, { useState } from "react";
import Logo from "../Component/Logo";
import { Link, NavLink } from "react-router-dom";
import { useFetchMenuQuery } from "../store/rtk/menu";
import useOutsideClick from "../Component/Common/useOutsideClick";


export default function Header() {
  const { isFetching, data } = useFetchMenuQuery();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [openSubMenuId, setSubOpenMenuId] = useState(null);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  
  const offcanvasRef = useOutsideClick(() => {
    if (isOffcanvasOpen) {
      setIsOffcanvasOpen(false);
      setOpenMenuId(null);
      setSubOpenMenuId(null);
    }
  });

  const toggleMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id); 
    setSubOpenMenuId(null); 
  };

  const toggleSubMenu = (id) => {
    setSubOpenMenuId(openSubMenuId === id ? null : id);
  };

  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false); 
    setOpenMenuId(null);
    setSubOpenMenuId(null);
  };

  const handleTogglerClick = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen); 
  };

  return (
    <nav className="navbar navbar-expand-lg nav_section">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <Logo />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleTogglerClick} 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`offcanvas offcanvas-end${isOffcanvasOpen ? ' show' : ''}`} 
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          ref={offcanvasRef} 
        >
          <div className="offcanvas-header">
            <Link className="navbar-brand" to="/" onClick={closeOffcanvas}>
              <Logo />
            </Link>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeOffcanvas} 
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {isFetching && (
                <li key="0" className="nav-item">
                  <span className="nav-link dropdown-toggle">
                    Loading Menu...
                  </span>
                </li>
              )}
              {data?.map((item, indx) => (
                <li
                  key={indx}
                  className={`nav-item dropdown menu_list ${
                    openMenuId === item.id ? "open" : ""
                  }`}
                >
                  <div className="mobile_btn_icon">
                    <NavLink
                      className={({ isActive }) =>
                        `nav-link dropdown-toggle ${
                          isActive ||
                          (item.title === "Media" &&
                            [
                              "/media/news",
                              "/media/events",
                              "/media/gallery",
                              "/media/communications",
                            ].includes(window.location.pathname))
                            ? "active"
                            : ""
                        }`
                      }
                      to={
                        item.path === "/nopage"
                          ? "javascript:void(0)"
                          : item.path
                      }
                      id={
                        openMenuId === item.id
                          ? "navbarDropdownMenuLink"
                          : undefined
                      }
                      aria-expanded={openMenuId === item.id}
                      onClick={() => {
                        toggleMenu(item.id);
                        closeOffcanvas(); // Close offcanvas when menu item is clicked
                      }}
                    >
                      {item.title}
                      
                    </NavLink>
                  
                    {(item.title === "Portfolio" ||
                      item.title === "Media") && (
                      <span
                        className="plus_icon"
                        onClick={() => {
                          toggleMenu(item.id);
                          // closeOffcanvas(); // Optional: You might not need to close offcanvas here
                        }}
                      >
                        
                        {openMenuId === item.id ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    )}
                  </div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                    style={{
                      display:
                        openMenuId === item.id
                          ? "showsub_menu"
                          : "hidesub_menu",
                    }} // Show/hide the menu
                  >
                    {item.children.map((sbmItem, index) => (
                      <li
                        className={`dropdown-submenu ${
                          openSubMenuId === sbmItem.id ? "open" : ""
                        }`}
                        key={index}
                      >
                        <div className="submenu-header">
                          <NavLink
                            className="dropdown-item dropdown-toggle"
                            {...(sbmItem.path !== "/nopage" && {
                              to: sbmItem.path,
                            })}
                            onClick={() => {
                              closeOffcanvas(); // Close offcanvas when submenu item is clicked
                            }}
                          >
                            {sbmItem.title}
                          </NavLink>

                          {!(
                            sbmItem.title === "News" ||
                            sbmItem.title === "Events" ||
                            sbmItem.title === "Gallery" ||
                            sbmItem.title === "Communications"
                          ) && (
                            <span
                              className="plus_icon"
                              onClick={() => {
                                toggleSubMenu(sbmItem.id);
                                // closeOffcanvas(); // Optional: You might not need to close offcanvas here
                              }}
                            >
                              {openSubMenuId === sbmItem.id ? (
                                <i className="fa-solid fa-minus"></i>
                              ) : (
                                <i className="fa-solid fa-plus"></i>
                              )}
                            </span>
                          )}
                        </div>
                        <ul
                          className="dropdown-menu"
                          style={{
                            display:
                              openSubMenuId === sbmItem.id
                                ? "showsub_menu"
                                : "hidesub_menu",
                          }} // Show/hide the submenu
                        >
                          {sbmItem.children.map((thirdItem, ind) => (
                            <li key={ind}>
                              <NavLink
                                className="dropdown-item"
                                target={
                                  thirdItem.externalPath != null ? "_blank" : ""
                                }
                                to={
                                  thirdItem.externalPath != null
                                    ? thirdItem.externalPath
                                    : thirdItem.path
                                }
                                onClick={closeOffcanvas} // Close offcanvas when third level item is clicked
                              >
                                {thirdItem.title}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
