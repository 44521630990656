import React,{useState} from "react";
import Banner from "./Banner";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { useFetchBlogDataQuery } from "../store/rtk/blogs";
import { Link } from "react-router-dom";
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";
export const Blog = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(6)
  const {isLoading,isFetching,data}=useFetchBlogDataQuery({ currentPage, pageSize })
 
  const handleShowMore = () => {
    setpageSize(pageSize + 3)
    setCurrentPage(1)
  };

  const getDay = (dateString) => {
    const date = new Date(dateString);
    return date.getDate(); 
  };
const getYear=(dateString)=>{
  const date = new Date(dateString);
  return date.getFullYear();
}
  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthIndex];
  };
 
  return (
    <>
    <Helmet>
        <title>Nucleus office park | Blog</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
    {isFetching && <Loader/>}
      {/* banner */}
      <section className="banner_image_common">
        <Banner urlName={"blogs"} />
      </section>
      {/* banner */}

      {/* blog */}
      <section className="news blog" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row gx-md-5">
            {data?.data?.map((item, i) => {             
              return (
                <div className="col-lg-4 col-md-6 card-group nuc_galiery ">
                  <Link to={`/blog-details/${item?.id}`}>
                  <div className="card news-card blog-card">
                    <img
                      src={REACT_APP_BASE_BACKEND_URL + item?.attributes?.Image?.data?.[0]?.attributes?.url}
                      alt="new-1.jpg"
                      className="card-img-top img-fluid"
                    />                 
                      <div className="card-body news-card-body "> 
                      <h3 className="fs-18 fw-bold title mb-0">{getMonthName(item?.attributes?.Posted_date)}<span> </span> {getDay(item?.attributes?.Posted_date)}, {getYear(item?.attributes?.Posted_date)}</h3>
                      <div className="card-text fs-17 mb-0 d-flex justify-content-between align-items-center">
                        <p className="card-text"> {item?.attributes?.Posted_by}</p>
                      <i class="bi bi-arrow-right right"></i>
                      </div>
                      <p className="fs-18 fw-500 mb-0 card-footer-text">
                      <p>{item?.attributes?.Title}</p>
                      </p>
                    </div>
                  </div>
                  </Link>
                </div>
              );
            })}
            {!isLoading && !isFetching && data && currentPage < Math.ceil(data.meta.pagination.total / pageSize) && (
            <div className="show_more mt-0">
              <button className="btn btn-dark btn-title" onClick={handleShowMore}>SHOW MORE</button>
            </div>
            )}
          </div>
        </div>
      </section>
      {/* blog */}
    </>
  );
};
