import { Route, Routes } from 'react-router-dom';
import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Layout from './Includes/Layout';
import MainComponent from './Component/MainComponent';
import AboutUs from './Component/AboutUs';
import News from './Component/News';
 import Portfoilo from './Component/Portfoilo';
 import Esg from './Component/Esg';
 import Mumbai from "./Component/Mumbai";
 import Contact from './Component/Contact';
import Gallery from './Component/Gallery';
import OneBkc from './Component/OneKbc';
import { OneInternationalCenter } from './Component/OneInternationalCenter';
import { Gurugram } from './Component/Gurugram';
import { KosmoOne } from './Component/KosmoOne';
import { OneWorldCenter } from './Component/OneWorldCenter';
import { Primabay } from './Component/Primabay';
import {OneUnityCenter}  from './Component/OneUnityCenter';
import {Bengaluru} from './Component/Bengaluru';
import {FintechOne} from './Component/FintechOne';
import {ConceptTechPark} from './Component/ConceptTechPark';
import {ExoraBusinessPark} from './Component/ExoraBusinessPark';
import {OneTradeTower} from './Component/OneTradeTower';
import {OneQube} from './Component/OneQube';
import {CessnaBusinessPark} from './Component/CessnaBusinessPark';
import { Events } from './Component/Events';
import { Communications } from './Component/Communications';
import { Career  } from './Component/Career ';
import LeaderShipDetailsPage from './Component/LeaderShipDetailsPage';
import { Blog } from './Component/Blog';
import PrivacyPolicy from './Component/PrivacyPolicy';
import BlogDetails from './Component/BlogDetails';
import Investors from './Component/Investors';
import oicplCommittee from './Component/oicplCommittee';
import OicplInvestorRelations from './Component/OicplInvestorRelations';
import NopplCompositionBoard from './Component/NopplCompositionBoard';
import OicplCorporateGovernance from './Component/OicplCorporateGovernance';
import OicplShareholders from './Component/OicplShareholders';
import OicplContactus from './Component/OicplContactus';
import OicplCsr from './Component/OicplCsr';
import NopplInvestorRelations from './Component/NopplInvestorRelations';
import NopplCorporateGovernance from './Component/NopplCorporateGovernance';
import NopplShareholdersMeetings from './Component/NopplShareholdersMeetings';
import NopplContactus from './Component/NopplContactus';
import NopplCsr from './Component/NopplCsr';
import EsgReport from './Component/EsgReport';
import { Helmet } from 'react-helmet';

import NotFound from './Component/NotFound';

function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  return (
    <>
   <Helmet>
<title>This is home page</title>
<meta 
name="description"
      content="this is home page of nuclous park"
/>
</Helmet>
   <Routes>
      <Route path='/' Component={Layout}>
          <Route index Component={MainComponent} />
          <Route path='/home' Component={MainComponent} />
          <Route path='/aboutus' Component={AboutUs}/> 
          <Route path= '/media/news' Component={News}/> 
          <Route path='/portfolio' Component={Portfoilo}  />   
          <Route path='/esg' Component={Esg} /> 
          <Route path='/portfolio/mumbai' Component={Mumbai}  />     
          <Route path='/contact' Component={Contact} /> 
          <Route path='/media/gallery' Component={Gallery}/>
          <Route path='/portfolio/mumbai/onebkc' Component={OneBkc}/>
          <Route path='/portfolio/mumbai/one-international-center' Component={OneInternationalCenter}/>
          <Route path='/portfolio/gurugram' Component={Gurugram}/>
          <Route path='/portfolio/gurugram/concept-tech-park' Component={ConceptTechPark}/>
          <Route path='/portfolio/gurugram/one-qube' Component={OneQube}/>
          <Route path='/portfolio/chennai/kosmo-one' Component={KosmoOne}/>
          <Route path='/portfolio/mumbai/one-world-center' Component={OneWorldCenter}/>
          <Route path='/portfolio/mumbai/prima-bay' Component={Primabay}/>
          <Route path='/portfolio/mumbai/one-unity-center' Component={OneUnityCenter}/>
          <Route path='/portfolio/bengaluru' Component={Bengaluru}/>
          <Route path='/portfolio/ahmedabad/fintech-one' Component={FintechOne}/>
          <Route path='/portfolio/bengaluru/cessna-business-park' Component={CessnaBusinessPark}/>
          <Route path='/portfolio/bengaluru/exora-business-park' Component={ExoraBusinessPark}/>
          <Route path='/portfolio/bengaluru/one-trade-tower' Component={OneTradeTower}/>
          <Route path='/media/events' Component={Events}/>
          <Route path='/media/communications' Component={Communications}/>
          <Route path='/careers' Component={Career }/>
          <Route path='/blog' Component={Blog }/>
          <Route path='/investors' Component={Investors }/>
         
          <Route path='/leader-ship-details-page/:id' Component={LeaderShipDetailsPage }/>
          <Route path='/privacy-policy' Component={PrivacyPolicy }/>
          <Route path='/blog-details/:id' Component={BlogDetails }/>
          <Route path='/oicpl-composition-of-board-n-committees' Component={oicplCommittee }/>
          <Route path='/oicpl-investor-relations' Component={OicplInvestorRelations }/>
          <Route path='/noppl-composition-of-board-n-committees' Component={NopplCompositionBoard }/>
          <Route path='/oicpl-corporate-governance' Component={OicplCorporateGovernance }/>
          <Route path='/oicpl-shareholders-meetings' Component={OicplShareholders }/>
          <Route path='/oicpl-contactus' Component={OicplContactus }/>
          <Route path='/oicpl-csr' Component={OicplCsr }/>
          <Route path='/noppl-investor-relations' Component={NopplInvestorRelations }/>
          <Route path='/noppl-corporate-governance' Component={NopplCorporateGovernance }/>
          <Route path='/noppl-shareholders-meetings' Component={NopplShareholdersMeetings }/>
          <Route path='/noppl-contactus' Component={NopplContactus }/>
          <Route path='/noppl-csr' Component={NopplCsr }/>
          <Route path='/esg-report' Component={EsgReport }/>
          <Route path='/portfolio/mumbai/one-unity-center' Component={OneUnityCenter }/>
          <Route path='*' element={<NotFound />} />
      </Route>
   </Routes>
   </> );
}

export default App;
