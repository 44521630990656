import React from "react";
import { REACT_APP_BASE_BACKEND_URL } from '../config';
import { Link } from "react-router-dom";
import { useFetchNopplIvestorRelationDataQuery } from "../store/rtk/nopplInvestorRelation";
import Banner from "./Banner";
import { Loader } from "../shared/Loader";
import { Helmet } from "react-helmet";


const NopplInvestorRelations = () => {
    const {isFetching,data}=useFetchNopplIvestorRelationDataQuery()
    
    return (
        <>  
          <Helmet>
        <title>Nucleus office park | Investors</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
         {isFetching && <Loader/>}
            <section
                className="banner_image_common"         
            >
                <Banner urlName={"investor-sub-pages"}/>          
            </section>
            {/* banner */}

            {/* NopplInvestorRelations */}
            <section className="investore">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light nav_section">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" id="nav-one-link" to="/oicpl-investor-relations" role="tab" aria-controls="nav-one" aria-selected="true">
                                    One International Center Private Limited
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" id="nav-office-link" to="/investors" role="tab" aria-controls="nav-office" aria-selected="false">
                                    Nucleus Office Parks Private Limited
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="row investore_heading justify-content-between  "><div className="col-md-2"><h1 className="mt-3 mb-lg-0 mb-4"><Link to="/investors"><i className="bi bi-arrow-left"></i> Back</Link></h1></div><div className="col-md-8 text-center"><h1 className="pt-0 ">{data?.data?.attributes?.Title}</h1></div><div className="col-md-2"></div></div>
                    <div className="nuc_galiery investore_page ">
                        <div className="row  gx-5 mb-2">
                            {data?.data?.attributes?.Noppl_investor_file?.slice().sort((a, b) => b.id - a.id)?.map((item, i) => {
                                return (
                                    <>                             
                                            <div className="col-lg-6 col-md-6 col-12 ">
                                            <a
                                            rel="noreferrer"
                                           href={ item?.File?.data !== null ? REACT_APP_BASE_BACKEND_URL + item?.File?.data?.[0]?.attributes?.url:"#"}
                                            target="_blank"
                                            className="inv_data"
                                        >
                                                <div className="commitem">
                                                    <div className="comm-title">
                                                        <i className="bi bi-file-earmark-text"></i>
                                                        {item?.Title}
                                                    </div>
                                                    <div className="btncol">

                                                        <i className="bi bi-arrow-right right"></i>

                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                      
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
            {/* NopplInvestorRelations */}
        </>
    );
};
export default NopplInvestorRelations;
