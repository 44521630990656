import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import CountUp from "react-countup";
import footprint from "../assets/images/footprint.svg";
import tenants from "../assets/images/corporate-tenants.svg";
import commercialassests from "../assets/images/commercial-assests.svg";
import parkusers from "../assets/images/park-users.svg";
import PortfolioBanner from "./PortfolioBanner";
import { useFetchMenuQuery } from "../store/rtk/menu";
import { useFetchAboutUsDataQuery } from "../store/rtk/aboutus";
import { Link } from "react-router-dom";
import { Loader } from "../shared/Loader";
export default function Portfoilo() {
  const { data: hierarchy } = useFetchMenuQuery();
  const [portfolio, setportfolio] = useState([]);
  const [ncrData, setNcrData] = useState([]);
  const [ahmedabadData, setAhmedabad] = useState([]);
  const [mumbaiDta, setmumbaiDta] = useState([]);
  const [bengloreData, setBengloreData] = useState([]);
  const [chennaiData, setChennaiData] = useState([]);
  const { isLoading, data } = useFetchAboutUsDataQuery();


  const menuOrdring = (hierarchy) => {
    const filterdData = hierarchy?.find((item) => item.title === "Portfolio");
    let myArray = [];
    filterdData?.children?.forEach((item, index) => {
      myArray.push(item.children);
    });
    setportfolio(myArray);
   

    setNcrData(myArray[1]);
    setAhmedabad(myArray[4]);
    setBengloreData(myArray[3]);
    setChennaiData(myArray[2]);
    setmumbaiDta(myArray[0]);
  };
  useEffect(() => {
    menuOrdring(hierarchy);
  }, [hierarchy]);
  return (
    <>

<Helmet>
<title>Nucleus Office Parks Portfolio- Premium Office Spaces & Facilities</title>
<meta 
name="description"
content="Explore Nucleus Office Parks' portfolio of innovative and premium commercial spaces, designed to meet the needs of modern businesses in prime locations."
/>
</Helmet>
      {isLoading && <Loader />}
      {/* <!-- portfoli banner --> */}
      <PortfolioBanner urlName={"portfolio"} />
      {/* <!-- portfoli banner --> */}
      {/* <!-- map section --> */}
      <section className="map_section" data-aos="fade-up" data-aos-duration="3000">
        <div className="container">
          <div className="row justify-content-center gy-5">
            <div className="col-lg-6 col-md-6">
              <div className="map-container">
                <div className="city ahmedabad">
                  <div className="label">{ahmedabadData && ahmedabadData[0]?.parent?.title}</div>

                  <div className="additional-info hover_items ">
                    <h4 className="map_heading">
                      {ahmedabadData && ahmedabadData[0]?.parent?.title}
                    </h4>
                    <h4 className="assets_item">1 Assets (0.4M SF) </h4>
                    {ahmedabadData && ahmedabadData?.map((item, index) => {
                      return (
                        <p className="map_item">
                          <i className="fa-solid fa-angle-right angle_right"></i>
                          <Link to={item?.path}>{item?.title}</Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="city mumbai">
                  <div className="label">{mumbaiDta && mumbaiDta[0]?.parent?.title}</div>
                  <div className="additional-info hover_items ">
                    <h4 className="map_heading">{mumbaiDta && mumbaiDta[0]?.parent?.title}</h4>
                    <h4 className="assets_item">5 Assets (6.1M SF)</h4>
                    {mumbaiDta && mumbaiDta?.map((item, index) => {

                      return (
                        <p className="map_item" key={index}>
                          <i className="fa-solid fa-angle-right angle_right"></i>
                          <Link to={item?.path}>{item?.title}</Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="city bangalore">
                  <div className="label">{bengloreData && bengloreData[0]?.parent?.title}</div>
                  <div className="additional-info  bg ">
                    <h4 className="assets_item">3 Assets (10.4M SF)</h4>
                    <h4 className="map_heading">
                      {bengloreData && bengloreData[0]?.parent?.title}
                    </h4>
                    {bengloreData && bengloreData?.map((item, index) => {
                      return (
                        <p className="map_item">
                          <i className="fa-solid fa-angle-right angle_right"></i>
                          <Link to={item?.path}>{item?.title}</Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="city chennai">
                  <div className="label">{chennaiData && chennaiData[0]?.parent?.title}</div>
                  <div className="additional-info hover_items ">
                    <h4 className="map_heading">{chennaiData && chennaiData[0]?.parent?.title}</h4>
                    <h4 className="assets_item">1 Assets (1.9M  SF)</h4>
                    {chennaiData && chennaiData?.map((item, index) => {
                      return (
                        <p className="map_item">
                          <i className="fa-solid fa-angle-right angle_right"></i>
                          <Link to={item?.path}>{item?.title}</Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="city ncr">
                  <div className="label">
                    {ncrData && ncrData[0]?.parent?.title}
                  </div>
                  <div className="additional-info hover_items ">
                    <h4 className="map_heading">
                      {ncrData && ncrData[0]?.parent?.title}
                    </h4>
                    <h4 className="assets_item"> 2 Assets (0.8M SF)</h4>

                    {ncrData && ncrData?.map((item, index) => {
                      return (
                        <p className="map_item">
                          <i className="fa-solid fa-angle-right angle_right"></i>
                          <Link to={item?.path}>{item?.title}</Link>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="nucleus_portfolio">
                <h3>Nucleus Office Parks</h3>
                <p className="portfolio_subcontent">As an asset management platform, we are committed to differentiated tenant engagement, a transformational approach, and sustainable operations. With our proactive asset management philosophy, we want to create ‘buildings of choice’ which maximize value for our customers and investments.</p>
                <p>Key Statistics</p>
              </div>

              <div className="port_sec counter_inc">
                <div className="row">
                  <div className="col-6">
                    <div className="portfolio_icon">
                      <div className="img_log">
                        <img src={footprint} alt="" className="img-fluid " />
                      </div>
                      <div className="counters">
                        <h3>
                          <span
                            className="counter"
                            data-countto="20"
                            data-duration="8000"
                          ></span>
                          <span className="stat-suffix">
                            <CountUp
                              end={
                                data?.data?.attributes?.portfolio_counts
                                  ?.data[0]?.attributes?.Footprint
                              }
                              decimals={1} // Set the number of decimal places
                            />
                            mn
                          </span>
                        </h3>

                        <p>Sqft of Footprint</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="portfolio_icon">
                      <div className="img_log">
                        <img src={tenants} alt="" className="img-fluid" />
                      </div>
                      <div className="counters">
                        <h3>
                          <span
                            className="counter"
                            data-countto="200"
                            data-duration="8000"
                          ></span>
                          <span className="stat-suffix">
                            <CountUp
                              end={
                                data?.data?.attributes?.portfolio_counts
                                  ?.data[0]?.attributes?.tenants
                              }
                            />
                            +
                          </span>
                        </h3>

                        <p>Corporate Tenants</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="portfolio_icon">
                      <div className="img_log">
                        <img
                          src={commercialassests}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="counters">
                        <h3>
                          <span
                            className="counter"
                            data-countto="16"
                            data-duration="8000"
                          >
                            <CountUp
                              end={
                                data?.data?.attributes?.portfolio_counts
                                  ?.data[0]?.attributes?.Assets
                              }
                            />
                          </span>
                        </h3>
                        <p>
                         Grade A Commercial<br></br>Parks
                      </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="portfolio_icon">
                      <div className="img_log">
                        <img src={parkusers} alt="" className="img-fluid" />
                      </div>
                      <div className="counters">
                        <h3>
                          <span
                            className="counter"
                            data-countto="120"
                            data-duration="8000"
                          >
                            <CountUp
                              end={
                                data?.data?.attributes?.portfolio_counts
                                  ?.data[0]?.attributes?.users
                              }
                            />
                          </span>
                          <span className="stat-suffix">k+</span>
                        </h3>
                        <p>Park Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- map section --> */}
    </>
  );
}
